<!-- 跳转使用 勿删 -->
<template>
  <div>
    <!-- 66666 -->
  </div>
</template>

<script>
  import { getAccessRecord } from '@/api/api'
  export default {
    data () {
      return {}
    },
    created() {
      window.document.title = ''
      let params = {
        mobile: this.$route.params.phone
      }
      window.location.href = 'https://oss.cmtibet.com/activity/2022/yutika7.0/index.html'
      getAccessRecord(params).then(res => {
        console.log(res)
      })
    },
  }
</script>